.ap {
    flex: none;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #d2d5dc;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.ap .apc {
    width: 6px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.ap .aph {
    width: 6px;
    height: 6px;
    background-color: #4374AD;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 4px;
    margin: auto;
    cursor: pointer;
}