.gpw {
    padding: 20px;
}

.gpw .trigger {
    padding: 5px;
    background: rgb(255, 255, 255);
    border-radius: 1px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 1px;
    display: inline-block;
    cursor: pointer;
}

.gpw .trigger .inner {
    width: 36px;
    height: 14px;
    border-radius: 2px;
}

.gpw .popover {
    z-index: 2;
    margin-top: 6px;
    box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 1px,
    rgba(0, 0, 0, 0.15) 0 8px 16px;
    padding: 12px;
    border-radius: 4px;
    position: absolute;
}

.gpw .popover .angle-holder {
    margin: 0 -10px;
    padding: 10px 0 0 10px;
    border-top: 1px solid rgb(238, 238, 238);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.gpw .popover .angle-inputs {
    border-radius: 4px;
    background: #f2f2f2;
    display: flex;
    flex: 1;
    margin: 0 20px;
    justify-content: space-around;
    align-items: center;
}

.gpw .popover .angle-inputs input {
    border: none;
    text-align: center;
    width: 48px;
    color: #0C0C09;
    background: inherit;
}

.gpw .popover .angle-inputs span {
    padding: 5px;
    cursor: pointer;
    user-select: none;
}

.gpw .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}