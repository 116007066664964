.cp div {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 16px;
  width: 16px;
}
.cp div:hover {
  border: 1px solid #fff;
}
